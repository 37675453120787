import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HfToastComponent, HfToastService } from '@monorepo/ui';
import { AppService, AuthService, NativeAudioService, NotificationService } from '@monorepo/utils';
import { Meta } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { SafeArea } from 'capacitor-plugin-safe-area';
// import { App } from '@capacitor/app';
import { URLS } from './shared/utils/urls';
import { FirebaseMessaging } from '@capacitor-firebase/messaging';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HfToastComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})

export class AppComponent implements OnInit {

  constructor(
    public appService: AppService,
    public authService: AuthService,
    private toastService: HfToastService,
    private router: Router,
    private notificationService: NotificationService,
    private meta: Meta,
    private nativeAudioService: NativeAudioService
  ) {
  }

  ngOnInit() {
    this.appService.setPlatform(environment.is_local);
    if (this.appService.isBrowserRendering) {
      this.appService.getDeviceInfo();
      this.appService.initBackButton(URLS);
      window.addEventListener('offline', () => {
        this.toastService.toastMessage.next({ title: `Please check your internet connection.`, msg: 'If you still have an issue, please contact Provider Services at 631-SELL-OIL or ProviderServices@HeatFleet.com', type: 'error' });
      });
      this.nativeAudioService.preloadAudio();
    }

    this.authService.setAuth('vendor', environment.api_url, environment.api_version, environment.cryptojs_key, environment.cryptojs_iv);
    if (this.appService.isIosApp) {
      SafeArea.getStatusBarHeight().then(({ statusBarHeight }) => {
        document.documentElement.style.setProperty('--status-bar-height', `${statusBarHeight}px`);
      });
    }

    // if (this.appService.isBrowserRendering) {
    //   App.addListener('backButton', (data: any) => {
    //     if (data.canGoBack) {
    //       window.history.back();
    //     } else {
    //       App.exitApp();
    //     }
    //   });
    // }

    if (this.appService.isApp) {
      this.notificationService.initNotificationService();
      FirebaseMessaging.addListener("notificationActionPerformed", (event) => {
        if (event) {
          this.router.navigate([URLS.ROOT]);
        }
      });
    }

    this.meta.updateTag({
      name: 'robots',
      content: 'noindex, nofollow',
    });
  }

  ngOnDestroy() {
    if (this.appService.isApp) {
      this.nativeAudioService.unloadAudio();
    }
  }
}
