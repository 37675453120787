export enum URLS {
  ROOT = "",
  LOGIN = "sign-in",
  SIGN_UP = "sign-up",
  OIL_PRICES = "oil-prices",
  PROPANE_PRICES = "propane-prices",
  PRICE_TIERS = "price-tiers",
  PROPANE_PRICE_TIERS = "propane-price-tiers",
  USER_PROFILE = "user-profile",
  ORDER_DETAIL = "order-detail",
  COMPANY_SETTINGS = "company-settings",
  TEAMS = "teams",
  ADD_TEAM_MEMBER = "add-team-member",
  EDIT_TEAMS_MEMBER = "edit-teams-member",
  ZONES = "zones",
  PROPANE_ZONES = "propane-zones",
  TEXT_PRICE = "text-price",
  HOME = "/",
  ORDER_SERVICE_DETAILS = "order-service-details",
  DAY = 'day',
  FORGET_PASSWORD = 'forget-password',
  USER_CONFIRM = "user-confirm",
  PRIVACY = 'privacy',
  TERMS = 'terms',
  TERMS_CONDITIONS = 'terms-conditions',
  TRANSACTIONS = 'transactions',
  COMPANY_SERVICES = 'company-services',
  COMPANY_SERVICES_ADD = 'add-service',
  COMPANY_SERVICES_EDIT = 'edit-service',
  GOD_LOGIN = "set-auth",
  ALL_HOMEOWNERS = 'homeowners',
  HOMEOWNER_DETAILS = 'homeowner-details',
  PROMOTIONS = 'promotions',
  PROMOTION_ADD = 'add-promotion',
  PROMOTION_EDIT = 'edit-promotion',
  TRANSFERS = 'transfers',
  ORDERS = 'orders'
}
